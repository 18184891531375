<template>
  <div class="Home" v-if="$store.getters.isLoggedIn">
<!--    <organiz-header/>-->
    <organiz-header
        v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN'
                || currentUser.authorities[0].authority === 'ROLE_PROFESSOR'
                || currentUser.authorities[0].authority === 'ROLE_PROCTOR'"/>
    <student-header v-show="currentUser.authorities[0].authority === 'ROLE_USER'"/>

    <main>
      <section class="uk-section uk-section-small uk-padding-remove">
        <div class="uk-container">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <router-link v-if="currentUser.authorities[0].authority === 'ROLE_PROCTOR'"
                             title="OrganizationProcExamList" tag="a" :to="{name: 'OrganizationProcExamList'}">
                  {{ $t("globViews.personalInfo.home") }}
                </router-link>
                <router-link v-else title="organization" tag="a" :to="{name: 'organization'}">
                  {{ $t("globViews.personalInfo.home") }}
                </router-link>
              </li>
              <li>
                <span>{{ $t("globViews.personalInfo.pInfo") }}</span>
              </li>
            </ul>
            <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
          </div>

          <div class="uk-width-1-1">
            <div v-if="currentUser.passType === 0">
              <div class="uk-alert-warning" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p>{{ $t("globViews.personalInfo.pChange") }}<a href="#modal-passwordchange"
                                                                uk-toggle>{{ $t("globViews.personalInfo.cMe") }}</a></p>
              </div>
            </div>
          </div>
          <div class="uk-grid-medium" uk-grid>

            <div class="uk-width-1-1 uk-width-1-4@m tm-aside-column">
              <app-my-navmenu
              ></app-my-navmenu>
            </div>

            <div class="uk-width-1-1 uk-width-expand@m">
              <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                <header class="uk-card-header"><h1 class="uk-h2">
                  {{ $t("globViews.personalInfo.pInfo") }}</h1></header>
                <div class="uk-card-body">
                  <div v-if="error" class="uk-alert-danger" uk-alert>
                    <p v-html="error" class="uk-text-center"></p>
                  </div>
                  <div v-if="message" class="uk-alert-success" uk-alert>
                    <p v-html="message" class="uk-text-center"></p>
                  </div>
                  <form class="uk-form-stacked">
                    <div class=" uk-child-width-1-1 uk-margin-right uk-margin-left" uk-grid>
                      <fieldset class="uk-fieldset">
                        <legend class="uk-h4">{{ $t("globViews.personalInfo.contact") }}</legend>
                        <div class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s" uk-grid>
                          <div><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.fName") }}</div>
                            <input class="uk-input" v-model="firstname" type="text"/></label>
                          </div>
                          <div><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.uName") }}</div>
                            <input class="uk-input" v-model="lastname" type="text"/></label></div>
                          <div><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.pNumber") }}</div>
                            <input v-model="phone" class="uk-input" type="tel"/></label>
                          </div>
                          <div><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.email") }}</div>
                            <input disabled v-model="email" class="uk-input" type="email"/></label>
                          </div>
                        </div>
                      </fieldset>
                      <s></s>
                      <fieldset class="uk-fieldset uk-hidden">
                        <legend class="uk-h4">{{ $t("globViews.personalInfo.orgaInfo") }}</legend>
                        <div class="uk-grid-small" uk-grid>
                          <div class="uk-width-1-1">
                            <label>
                              <div class="uk-form-label">{{ $t("globViews.personalInfo.country") }}</div>

                              <select :class="{ 'uk-form-danger': validcountry }" @change="onChangeValue($event)"
                                      v-if="countries" v-model="country" class="uk-select"
                                      id="country">

                                <option v-for="(item, index) in countries" :key="index" :value="item.id"
                                >{{ item.name }}
                                </option>

                              </select>

                            </label>
                          </div>
                        </div>


                        <div class="uk-grid-small" uk-grid>
                          <div class="uk-width-1-1">
                            <label>
                              <div class="uk-form-label">{{ $t("globViews.personalInfo.state") }}</div>
                              <select :class="{ 'uk-form-danger': validstate }" @change="onChangeState($event)"
                                      v-model="state"
                                      class="uk-select"
                                      id="state">
                                <option value="">select item ...</option>
                                <option v-for="(item, index) in states" :key="index" :value="item.id">{{ item.name }}
                                </option>

                              </select>
                            </label></div>
                        </div>


                        <div class="uk-grid-small" uk-grid>
                          <div class="uk-width-expand"><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.city") }}</div>
                            <input class="uk-input" type="text"/></label></div>
                          <div class="uk-width-1-3 uk-width-1-6@s"><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.pCode") }}</div>
                            <input class="uk-input" type="text"/></label></div>
                        </div>
                        <div class="uk-grid-small" uk-grid>
                          <div class="uk-width-expand"><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.street") }}</div>
                            <input class="uk-input" type="text"/></label></div>
                          <div class="uk-width-1-3 uk-width-1-6@s"><label>
                            <div class="uk-form-label">{{ $t("globViews.personalInfo.house") }}</div>
                            <input class="uk-input" type="text"/></label></div>
                        </div>
                      </fieldset>
                    </div>
                  </form>
                </div>
                <div class="uk-card-footer uk-text-center">
                  <button type="button" @click="modifyUser" class="uk-button uk-button-primary">
                    {{ $t("globViews.personalInfo.save") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>


    <organiz-footer/>
  </div>
</template>


<script>
import UserService from '@/services/UserService'
import {mapGetters} from 'vuex'
import MyNav from '@/components/GlobViews/MyNavmenu'

import OrganizHeader from '@/components/Organizcmp/OrganizHeader.vue'
import OrganizFooter from '@/components/Organizcmp/OrganizFooter.vue'
import StudentHeader from '@/components/Studentcmp/StudentHeaderNew.vue'

export default {
  name: 'Home',
  components: {
    appMyNavmenu: MyNav,
    OrganizHeader,
    OrganizFooter,
    StudentHeader,
  },
  data() {
    return {
      layout: 'OrganizLayout',
      countries: null,
      states: null,
      country: 0,
      state: null,
      validcountry: false,
      validstate: false,
      error: null,
      message: null,
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      address: ''
    }
  },
  created() {
    // if (this.currentUser.authorities[0].authority === 'ROLE_USER') {
    //   this.layout = "StudentLayout";
    // } else {
    //   this.layout = "OrganizLayout";
    // }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },
  mounted() {
    this.firstname = this.currentUser.firstName
    this.lastname = this.currentUser.lastName
    this.email = this.currentUser.email
    this.phone = this.currentUser.phone
  },
  methods: {
    async me() {
      try {
        const response = await UserService.me();
        this.$store.dispatch("setUser", response.data);
        this.scrollToTop()
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    async modifyUser() {
      try {
        let formData = new FormData();
        formData.append('firstname', this.firstname);
        formData.append('lastname', this.lastname);
        formData.append('phone', this.phone);
        formData.append('country', this.country);
        formData.append('state', this.state);
        // formData.append('postcode', this.postcode);
        // formData.append('street', this.street);
        // formData.append('doornumber', this.doornumber);
        // formData.append('gender', this.gender);
        // formData.append('password', this.password);
        formData.append('email', this.email);
        // formData.append('organizationCode', this.$store.getters.currentUser.organiz.organizationCode);
        // formData.append('type', 'student');
        // if (this.file)
        // formData.append('image', this.file);
        const response = await UserService.modifyUser(formData)
        if (response.data.status === 200) {
          this.message = response.data.message
          this.me()
        }
      } catch (error) {
        this.error = error.response.data.message
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onChangeValue: function () {
      this.getStates(this.country)
      if (this.country) {
        this.validcountry = false
      }
    },
  }
}
</script>

<style scoped>
</style>
